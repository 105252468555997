import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { parse } from 'query-string'
import { prop, uniqBy } from 'ramda'

import { WellnessPlanLevelsLabels } from '../../../../constants/wellnessPlansConstants'
import { getCurrentClient } from '../../../../store/duck/clients'
import {
  getMembershipIsPortalEmbed,
  getWellnessPlansVersion,
} from '../../../../store/duck/wellnessPlans'
import { useNavigateWithQueryString } from '../../../../utils'
import useBoopEnabled from '../../../../utils/useBoopEnabled'
import KioskScreen from '../../KioskScreen'
import WellnessPlanPriceLabel from '../WellnessPlanPriceLabel'
import { getBenefitIsAccessToBoop, getEnabledPlans } from '../wellnessPlanUtils'
import PlanDetailsBenefitRow from './PlanDetailsBenefitRow'
import PlanDetailsBenefitRowMobile from './PlanDetailsBenefitRowMobile'

const useStyles = makeStyles(
  (theme) => ({
    tableHeader: {
      borderBottom: theme.constants.tableBorder,
    },
    tableBody: {
      maxHeight: ({ isPortalEmbed }) =>
        `calc(var(--app-height) - ${isPortalEmbed ? 225 : 395}px)`,
      [theme.breakpoints.down('sm')]: {
        maxHeight: () => 'calc(var(--app-height) - 285px)',
      },
      overflowY: 'auto',
    },
    tableRow: {
      borderBottom: theme.constants.tableBorder,
      '&:not(:last-child)': {
        borderBottom: theme.constants.tableBorderHeavy,
      },
    },
    tableCell: {
      padding: theme.spacing(1.5, 1),
    },
    planCell: {
      paddingLeft: theme.spacing(2),
    },
    borderCell: {
      borderRight: theme.constants.tableBorder,
    },
    limitsCell: {
      minWidth: 125,
    },
    tableHeaderText: {
      color: theme.colors.tabLabel,
      fontWeight: 500,
      fontSize: '1.4rem',
    },
    planNameText: {
      wordBreak: 'break-word',
      color: theme.colors.secondaryText,
      fontWeight: 500,
      fontSize: '2rem',
    },
    priceText: {
      fontSize: '1.4rem',
    },
    button: {
      marginRight: theme.spacing(2),
    },
  }),
  { name: 'MembershipPlanDetailsScreen' },
)

const MembershipPlanDetailsScreen = () => {
  const navigateWithQueryString = useNavigateWithQueryString()
  const { search } = useLocation()
  const { showFullDetails } = parse(search)
  const { t } = useTranslation('Common')

  const wellnessPlansVersion = useSelector(getWellnessPlansVersion)
  const isPortalEmbed = useSelector(getMembershipIsPortalEmbed)
  const client = useSelector(getCurrentClient)
  const isBoopEnabled = useBoopEnabled()

  const classes = useStyles({ isPortalEmbed })

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const plans = getEnabledPlans(wellnessPlansVersion)

  const onProceed = () => {
    if (client) {
      navigateWithQueryString({ url: '/membership/sign-up' })
    } else {
      navigateWithQueryString({ url: '/membership/phone' })
    }
  }

  return (
    <KioskScreen
      hideBackButton={showFullDetails}
      justifyContent="flex-start"
      proceedButtonLabel={
        isPortalEmbed ? t('Common:SIGN_CLIENT_UP') : t('Common:CONTINUE_ACTION')
      }
      onProceed={onProceed}
    >
      {!isMobile && (
        <Grid container item className={classes.tableHeader}>
          <Grid
            item
            className={classNames(classes.planCell, classes.tableCell)}
            xs={3}
          >
            <Typography className={classes.tableHeaderText}>
              {t('Common:PLAN')}
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs className={classes.tableCell}>
              <Typography className={classes.tableHeaderText}>
                {t('Common:BENEFIT')}
              </Typography>
            </Grid>
            <Grid
              item
              className={classNames(classes.tableCell, classes.limitsCell)}
            >
              <Typography className={classes.tableHeaderText}>
                {t('Common:LIMITS')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs className={classes.tableCell}>
            <Typography className={classes.tableHeaderText}>
              {t('Common:COVERED')}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container item className={classes.tableBody}>
        {plans.map((plan) => {
          const benefits = uniqBy(prop('globalBenefitId'), plan.benefits || [])
          const filteredBenefits = isBoopEnabled
            ? benefits
            : benefits.filter((benefit) => !getBenefitIsAccessToBoop(benefit))

          return (
            <Grid container item className={classes.tableRow} key={plan.id}>
              <Grid
                container
                item
                className={classNames(
                  classes.tableCell,
                  classes.borderCell,
                  classes.planCell,
                )}
                direction="column"
                sm={3}
                xs={4}
              >
                <Typography className={classes.planNameText}>
                  {plan?.name || WellnessPlanLevelsLabels[plan.level]}
                </Typography>
                <WellnessPlanPriceLabel
                  className={classes.priceText}
                  plan={plan}
                  wellnessPlansVersion={wellnessPlansVersion}
                />
              </Grid>
              <Grid container item xs direction="column">
                {filteredBenefits.map((benefit) =>
                  isMobile ? (
                    <PlanDetailsBenefitRowMobile
                      benefit={benefit}
                      key={benefit.id}
                    />
                  ) : (
                    <PlanDetailsBenefitRow benefit={benefit} key={benefit.id} />
                  ),
                )}
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </KioskScreen>
  )
}

export default MembershipPlanDetailsScreen
