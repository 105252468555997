import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { ClassesType, PuiTheme } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    button: {
      width: '100%',
      height: 56,
      border: theme.constants.inactiveBorder,
      borderRadius: 28,
      color: theme.colors.tabLabel,
      fontWeight: 500,
      fontSize: '1.6rem',
      textTransform: 'none',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    buttonSelected: {
      color: theme.colors.primaryText,
      backgroundColor: theme.colors.selectedOption,
      '&:hover': {
        color: theme.colors.primaryText,
        backgroundColor: theme.colors.selectedOption,
      },
    },
  }),
  { name: 'KioskSelectableButton' },
)

interface KioskSelectableButtonProps extends ButtonProps {
  readonly children: React.ReactNode
  readonly classes?: ClassesType<typeof useStyles>
  readonly isSelected?: boolean
}

const KioskSelectableButton = ({
  children,
  isSelected,
  classes: classesProp,
  ...rest
}: KioskSelectableButtonProps) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Button
      className={classNames(classes.button, {
        [classes.buttonSelected]: isSelected,
      })}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default KioskSelectableButton
