import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'

import {
  fetchBusiness,
  getCurrentBusiness,
} from '../../../store/duck/businesses'
import {
  getCurrentClient,
  updateCurrentClient,
} from '../../../store/duck/clients'
import { fetchConstants } from '../../../store/duck/constants'
import {
  fetchMembershipSignUpDataForToken,
  fetchWellnessPlansVersion,
  setMembershipIsPortalEmbed,
  updateMembershipBillingAddresses,
} from '../../../store/duck/wellnessPlans'
import FullscreenCircularProgress from '../../FullscreenCircularProgress'
import KioskWrapper from '../../KioskWrapper'
import membershipRoutes from './membershipRoutes'

const MembershipWrapperScreen = () => {
  const { search } = useLocation()
  const { businessId: businessIdParam, portalEmbed, token } = parse(search)
  const dispatch = useDispatch()
  const currentBusiness = useSelector(getCurrentBusiness)
  const client = useSelector(getCurrentClient)

  const { id } = currentBusiness || {}
  const businessId = businessIdParam || id
  const isPortalEmbed = Boolean(portalEmbed)

  const rootUrl = token ? '/membership' : '/'

  useEffect(() => {
    if (businessId) {
      if (!isPortalEmbed) {
        dispatch(fetchBusiness(businessId))
      }
      dispatch(fetchConstants(businessId))
    }
  }, [businessId])

  useEffect(() => {
    if (token) {
      dispatch(fetchMembershipSignUpDataForToken(token))
    } else {
      dispatch(fetchWellnessPlansVersion(businessId))
    }
  }, [token])

  useEffect(() => {
    dispatch(setMembershipIsPortalEmbed(isPortalEmbed))
  }, [isPortalEmbed])

  const handleIframeMessage = (event) => {
    if (event.data.type === 'PROVIDE_CLIENT') {
      dispatch(updateMembershipBillingAddresses(event.data.billingAddresses))
      dispatch(updateCurrentClient(event.data.client))
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage)
    return () => {
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [])

  const showSpinner = !currentBusiness

  if (showSpinner) {
    return <FullscreenCircularProgress />
  }

  return (
    <KioskWrapper
      businessLogoClickable={!token}
      hideBusinessInfo={isPortalEmbed}
      hideBusinessLogo={isPortalEmbed}
      redirectCondition={!client}
      rootUrl={rootUrl}
      routes={membershipRoutes}
    />
  )
}

export default MembershipWrapperScreen
