'use client'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { KibGrid, KibGridItem } from '@chewy/kib-layout-react'
import makeStyles from '@mui/styles/makeStyles'
import braintree, { HostedFields } from 'braintree-web'

import {
  createBraintreeMembershipPayment,
  getBraintreeToken,
} from '../../../../store/duck/braintree'
import {
  // @ts-ignore
  getMembershipBillingAddress,
  getMembershipToken,
} from '../../../../store/duck/wellnessPlans'
import { BraintreePaymentData } from '../../../../types/entities/memberships'
import { BraintreeField } from '../../../inputs/BraintreeField/BraintreeField'

interface BraintreePaymentFormProps {
  readonly setIsLoading: (value: React.SetStateAction<boolean>) => void
}

const useStyles = makeStyles(
  () => ({
    container: {
      width: '60%',
    },
  }),
  { name: 'BraintreePaymentForm' },
)

export const BraintreePaymentForm = React.forwardRef<
  HTMLFormElement,
  BraintreePaymentFormProps
>((props: BraintreePaymentFormProps, ref) => {
  const { setIsLoading } = props
  const dispatch = useDispatch()
  const [hostedFields, setHostedFields] = useState<HostedFields | null>(null)
  const [cardholderNameError, setCardholderNameError] = useState(false)
  const [cardNumberError, setCardNumberError] = useState(false)
  const [cvvError, setCvvError] = useState(false)
  const [expirationDateError, setExpirationDateError] = useState(false)

  const braintreeToken = useSelector(getBraintreeToken)
  const membershipToken = useSelector(getMembershipToken)
  const classes = useStyles()

  useEffect(() => {
    async function initializeBraintree() {
      try {
        const clientInstance = await braintree.client.create({
          authorization: braintreeToken,
        })

        const braintreeFields = await braintree.hostedFields.create({
          fields: {
            cardholderName: {
              container: '#cardholder_name',
            },
            number: {
              container: '#card_number',
            },
            expirationDate: {
              container: '#expiration_date',
              placeholder: 'MM/YY',
            },
            cvv: {
              container: '#cvv',
            },
          },
          styles: {
            input: {
              'padding-left': '1.2rem',
              'font-size': '1rem',
              'line-height': '1.25rem',
            },
          },
          client: clientInstance,
        })
        setHostedFields(braintreeFields)
      } catch (err) {
        // TODO: Handle errors
        // eslint-disable-next-line no-console
        console.log(err)
      }
    }
    initializeBraintree()
  }, [braintreeToken])

  const billingAddress = useSelector(getMembershipBillingAddress) || {}
  const validateBraintreeState = () => {
    if (hostedFields) {
      const {
        fields: { cardholderName, number: cardNumber, cvv, expirationDate },
      } = hostedFields.getState()
      setCardholderNameError(!cardholderName.isValid)
      setCardNumberError(!cardNumber.isValid)
      setCvvError(!cvv.isValid)
      setExpirationDateError(!expirationDate.isValid)
      return (
        cardholderName.isValid &&
        cardNumber.isValid &&
        cvv.isValid &&
        expirationDate.isValid
      )
    }
    return true
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let braintreePaymentData: BraintreePaymentData = {
      extendedAddress: billingAddress.apartmentNumber,
      firstName: billingAddress.firstName,
      lastName: billingAddress.lastName,
      locality: billingAddress.city,
      postalCode: billingAddress.zip,
      region: billingAddress.country,
      streetAddress: billingAddress.street,
      brandCode: '',
      cardBin: '',
      cardLastFour: '',
      nonce: '',
    }

    const isValidBraintreeState = validateBraintreeState()
    if (!isValidBraintreeState) {
      setIsLoading(false)
    }

    if (hostedFields && isValidBraintreeState) {
      const braintreeData = await hostedFields.tokenize()
      braintreePaymentData = {
        ...braintreePaymentData,
        brandCode: braintreeData.details.cardType,
        cardBin: braintreeData.details.bin,
        cardLastFour: braintreeData.details.lastFour,
        nonce: braintreeData.nonce,
      }
      dispatch(
        createBraintreeMembershipPayment({
          membershipToken,
          braintreePaymentData,
        }),
      )
    }
  }

  return (
    <form className={classes.container} ref={ref} onSubmit={handleSubmit}>
      <BraintreeField
        errorMessage={
          cardholderNameError ? 'Please enter the cardholder name' : ''
        }
        fieldId="cardholder_name"
        labelText="Cardholder Name"
      />
      <BraintreeField
        errorMessage={
          cardNumberError ? 'Please enter a valid credit card number' : ''
        }
        fieldId="card_number"
        labelText="Card Number"
      />
      <KibGrid>
        <KibGridItem span="4@min-xs 6@min-lg">
          <BraintreeField
            errorMessage={
              expirationDateError ? 'Please enter a valid expiration date' : ''
            }
            fieldId="expiration_date"
            labelText="Expiration Date"
          />
        </KibGridItem>

        <KibGridItem span="4@min-xs 6@min-lg">
          <BraintreeField
            errorMessage={cvvError ? 'Please enter a valid CVV' : ''}
            fieldId="cvv"
            labelText="CVV"
          />
        </KibGridItem>
      </KibGrid>
    </form>
  )
})
