import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { CircularProgress, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { parse } from 'query-string'
import { PuiTheme, Text } from '@pbt/pbt-ui-components'
import {
  Fill as FillIcon,
  Warning as WarningIcon,
} from '@pbt/pbt-ui-components/src/icons'

import {
  getCurrentBusiness,
  updateCurrentBusiness,
} from '../../../store/duck/businesses'
// @ts-ignore
import {
  getClientsError,
  getClientsIsLoading,
  verifyClientEmailByToken,
  // @ts-ignore
} from '../../../store/duck/clients'
import KioskScreen from '../KioskScreen'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    icon: {
      marginBottom: theme.spacing(2),
      fontSize: theme.spacing(6),
      marginTop: theme.spacing(16),
    },
    successIcon: {
      color: theme.colors.success,
    },
    warningIcon: {
      color: theme.colors.errorColor,
    },
  }),
  { name: 'ClientEmailVerificationScreen' },
)

const ClientEmailVerificationScreen = () => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation('Common')
  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('sm'),
  )

  const error = useSelector(getClientsError)
  const isLoading = useSelector(getClientsIsLoading)
  const currentBusiness = useSelector(getCurrentBusiness)

  const { token } = useParams()
  const { clientId: clientIdParam, businessId: businessIdParam } = parse(search)

  useEffect(() => {
    dispatch(
      updateCurrentBusiness({
        id: businessIdParam as string,
      }),
    )
    if (clientIdParam) {
      dispatch(verifyClientEmailByToken(clientIdParam as string, token))
    }
  }, [businessIdParam, clientIdParam])

  return (
    <KioskScreen
      alignItems="center"
      justifyContent={isLoading ? 'center' : 'flex-start'}
    >
      {isLoading || !currentBusiness ? (
        <CircularProgress />
      ) : (
        <Grid
          container
          item
          alignItems="center"
          direction="column"
          justifyContent="center"
          p={1}
        >
          {error ? (
            <WarningIcon
              className={classNames(classes.icon, classes.warningIcon)}
            />
          ) : (
            <FillIcon
              className={classNames(classes.icon, classes.successIcon)}
            />
          )}
          <Text strong variant={isMobile ? 'subheading2' : 'hero'}>
            {error
              ? t('Common:EMAIL_VERIFICATION_TOKEN_IS_WRONG')
              : t('Common:THANKS_FOR_VERIFYING_YOUR_EMAIL')}
            !
          </Text>
        </Grid>
      )}
    </KioskScreen>
  )
}

export default ClientEmailVerificationScreen
