import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { PuiCheckbox } from '@pbt/pbt-ui-components'

import { getCurrentBusinessIsOmniChannel } from '../../../store/duck/businesses'
import { getCurrentClient } from '../../../store/duck/clients'
import { useNavigateWithQueryString } from '../../../utils'
import KioskScreen from '../KioskScreen'
import OmnichannelWellnessPlanTermsAndConditions from './OmnichannelWellnessPlanTermsAndConditions'
import useHandleFreeSignUp from './useHandleFreeSignUp'
import WellnessPlanTermsAndConditions from './WellnessPlanTermsAndConditions'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      textAlign: 'left',
      padding: theme.spacing(2, 4, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 1, 0),
      },
    },
    checkbox: {
      margin: 'auto',
      fontSize: '1.4rem',
    },
  }),
  { name: 'MembershipTermsAndConditionsScreen' },
)

const MembershipTermsAndConditionsScreen = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const location = useLocation()
  const { redirectUrl } = location.state || {}
  const { t } = useTranslation('Common')
  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )

  const client = useSelector(getCurrentClient)

  const [scrolled, setScrolled] = useState(false)
  const [accepted, setAccepted] = useState(false)

  const searchParams = new URLSearchParams(location.search)
  const viewOnly = searchParams.get('view') === 'true'

  const { handler: handleFreeSignUp, isLoading: signUpIsLoading } =
    useHandleFreeSignUp()

  const onProceed = () => {
    if (redirectUrl) {
      navigateWithQueryString({ url: redirectUrl, options: { replace: true } })
    } else if (client?.isBoopUser) {
      handleFreeSignUp()
    } else {
      navigateWithQueryString({ url: '/membership/email-verification' })
    }
  }

  const onScroll = (event) => {
    if (
      event.target.scrollTop >
      event.target.scrollHeight - event.target.clientHeight - 20
    ) {
      setScrolled(true)
    }
  }

  return (
    <KioskScreen
      additionalLabel={
        isCurrentBusinessOmniChannel && !viewOnly ? (
          <PuiCheckbox
            checked={accepted}
            className={classes.checkbox}
            disabled={!scrolled}
            label="I have read and accept these terms and conditions"
            onChange={() => {
              setAccepted(!accepted)
            }}
          />
        ) : null
      }
      classes={{
        title: classes.title,
      }}
      justifyContent="flex-start"
      proceedButtonDisabled={
        !viewOnly && (!scrolled || (isCurrentBusinessOmniChannel && !accepted))
      }
      proceedButtonLabel={t('Common:CONTINUE_ACTION')}
      proceedButtonLoading={signUpIsLoading}
      title={t('Common:TERMS_AND_CONDITIONS')}
      onProceed={onProceed}
    >
      {isCurrentBusinessOmniChannel ? (
        <OmnichannelWellnessPlanTermsAndConditions onScroll={onScroll} />
      ) : (
        <WellnessPlanTermsAndConditions onScroll={onScroll} />
      )}
    </KioskScreen>
  )
}

export default MembershipTermsAndConditionsScreen
