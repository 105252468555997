enum Features {
  APPOINTMENT_SCHEDULING_UNASSIGNED = 'APPOINTMENT_SCHEDULING_UNASSIGNED',
  BOOP_DISABLEMENT = 'BOOP_DISABLEMENT',
  CATALOG_CODE_COUNTRY_FOR_CLIENT_ADDRESS_IN_KIOSK = 'CATALOG_CODE_COUNTRY_FOR_CLIENT_ADDRESS_IN_KIOSK',
  CLIENT_EMAIL_VERIFICATION = 'CLIENT_EMAIL_VERIFICATION',
  IPO_M2B_BRAINTREE_SIGNUP = 'IPO-M2B_BRAINTREE_SIGNUP',
  GDPR_KIOSK = 'GDPR_KIOSK',
  I18N = 'I18N',
  SMS_CONSENT_TOGGLE = 'SMS_CONSENT_TOGGLE',
  SUPPRESS_ADD_CLIENTS_AND_PATIENTS = 'SUPPRESS_ADD_CLIENTS_AND_PATIENTS',
  WAIT_LIST_ESTIMATED_TIME = 'WAIT_LIST_ESTIMATED_TIME',
  WELCOME_EMAIL_FOR_OMNICHANNEL = 'WELCOME_EMAIL_FOR_OMNICHANNEL',
  WELLNESS_PLANS = 'WELLNESS_PLANS',
  WP_AUTO_RENEWAL = 'WP_AUTO_RENEWAL',
  WP_EXTRA = 'WP_EXTRA',
  WP_MULTIPLE_PRICES = 'WP_MULTIPLE_PRICES',
  WP_ONE_TIME_FEE = 'WP_ONE_TIME_FEE',
  WP_OPEN_BOOP = 'WP_OPEN_BOOP',
  WP_TERMS_AND_CONDITIONS = 'WP_TERMS_AND_CONDITIONS',
}

export default Features
