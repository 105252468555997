import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonWithLoader, PuiTheme } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import KioskScreen from '../../KioskScreen'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    header: {
      marginTop: theme.spacing(3),
      fontSize: 20,
      fontWeight: 500,
    },
    text: {
      marginTop: theme.spacing(1),
    },
    icon: {
      width: 40,
      height: 40,
      color: theme.colors.alertWarning,
    },
    button: {
      backgroundColor: '#1C49C2',
      minWidth: 145,
      minHeight: 40,
      marginTop: theme.spacing(3),
    },
  }),
  { name: 'MembershipSignUpFailScreen' },
)

export const MembershipSignUpFailScreen = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { t } = useTranslation('Common')

  return (
    <KioskScreen>
      <Grid container item alignItems="center" direction="column">
        <Grid item>
          <WarnAlert className={classes.icon} />
        </Grid>
        <Grid>
          <Typography align="center" className={classes.header}>
            {t('Common:PAYMENT_FAILED')}
          </Typography>
        </Grid>
        <Grid className={classes.text}>
          {t('Common:PLEASE_CHECK_PAYMENT_INFO_RETRY')}
        </Grid>
        <ButtonWithLoader
          className={classes.button}
          onClick={() => navigate(-2)}
        >
          {t('Common:TRY_AGAIN')}
        </ButtonWithLoader>
      </Grid>
    </KioskScreen>
  )
}
