import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AlertIconType,
  PuiAlert,
  PuiTextField,
  PuiTheme,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'

import { getAppointmentTypesMap } from '../../store/duck/appointmentTypes'
import { getEventState } from '../../store/duck/constants'
// @ts-ignore
import { getIsEmbedded, getIsJoinWaitlistFlow } from '../../store/duck/flow'
import {
  clearSlotsUnavailableError,
  createWaitlistAppointment,
  getCurrentAppointment,
  getSchedulesError,
  getSchedulesIsLoading,
  getSlotsAreUnavailable,
  updateCurrentAppointment,
} from '../../store/duck/schedules'
import { AppointmentToCreateFromStore } from '../../types/entities/appointments'
// @ts-ignore
import { useNavigateWithQueryString } from '../../utils'
// @ts-ignore
import useCallbackWhenLoaded from '../../utils/useCallbackWhenLoaded'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    content: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    textField: {
      maxWidth: 520,
    },
    input: {
      fontSize: '1.8rem',
    },
    multiline: {
      padding: theme.spacing(2),
    },
  }),
  { name: 'PrimaryComplaintScreen' },
)

const PrimaryComplaintScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')

  const EventState = useSelector(getEventState)
  const appointment = useSelector(
    getCurrentAppointment,
  ) as AppointmentToCreateFromStore
  const isLoading = useSelector(getSchedulesIsLoading)
  const isEmbedded: boolean = useSelector(getIsEmbedded)
  const isWaitlist = useSelector(getIsJoinWaitlistFlow)
  const error = useSelector(getSchedulesError)
  const slotsAreUnavailable = useSelector(getSlotsAreUnavailable)
  const appointmentTypesMap = useSelector(getAppointmentTypesMap)

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('sm'),
  )

  const arrivedId: string = Utils.findConstantIdByName('Arrived', EventState)

  const {
    fields: { primaryComplaint },
    validate,
  } = useFields([{ name: 'primaryComplaint', initialValue: '' }])

  const onAppointmentCreated = () => {
    if (!error) {
      navigateWithQueryString({ url: '/questions' })
    }
  }

  const callbackWhenLoaded = useCallbackWhenLoaded(
    onAppointmentCreated,
    getSchedulesIsLoading,
  )
  const onProceed = () => {
    if (validate()) {
      const newAppointment = {
        ...appointment,
        notes: primaryComplaint.value,
      }
      dispatch(updateCurrentAppointment(newAppointment))

      if (isWaitlist) {
        const newWaitlistAppointment = {
          ...newAppointment,
          type: appointmentTypesMap[appointment.type]?.eventTypeId,
          businessAppointmentType: { id: appointment.type },
        }
        callbackWhenLoaded()
        dispatch(
          createWaitlistAppointment(
            !isEmbedded
              ? { ...newWaitlistAppointment, state: arrivedId }
              : newWaitlistAppointment,
            isEmbedded,
          ),
        )
      } else {
        navigateWithQueryString({ url: '/calendar' })
      }
    }
  }

  return (
    <KioskScreen
      classes={{
        content: classes.content,
      }}
      justifyContent={isMobile ? 'flex-start' : 'center'}
      proceedButtonLabel={t('Common:NEXT_ACTION')}
      proceedButtonLoading={isLoading}
      title={t(
        'Common:IS_THERE_ANYTHING_THAT_YOU_WOULD_LIKE_YOUR_DOCTOR_TO_KNOW',
      )}
      onProceed={onProceed}
    >
      <PuiTextField
        fullWidth
        multiline
        InputProps={{
          inputProps: {
            maxLength: 1000,
            className: classes.input,
          },
          classes: {
            multiline: classes.multiline,
          },
        }}
        className={classes.textField}
        field={primaryComplaint}
        placeholder={t('Common:TYPE_HERE')}
        rows={10}
        type="text"
        variant="outlined"
      />
      <PuiAlert
        cancelButtonText={t('Common:CANCEL_ACTION')}
        iconType={AlertIconType.WARN}
        message={t('Common:SORRY_THERE_ARE_NO_FREE_SLOTS_FOR_APPOINTMENT')}
        okButtonText={t('Common:BACK_ACTION')}
        open={slotsAreUnavailable}
        onClose={() => {
          dispatch(clearSlotsUnavailableError())
        }}
        onOk={() => {
          dispatch(clearSlotsUnavailableError())
          navigateWithQueryString({ url: '/appointment-type' })
        }}
      />
    </KioskScreen>
  )
}

export default PrimaryComplaintScreen
