import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { DateUtils } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../../store/duck/businesses'
import { getMembershipIsPortalEmbed } from '../../../store/duck/wellnessPlans'
import KioskLinkButton from '../../buttons/KioskLinkButton'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginRight: theme.spacing(2),
      padding: theme.spacing(0, 2),
      overflowY: 'auto',
      maxHeight: ({ isPortalEmbed }) =>
        `calc(var(--app-height) - ${isPortalEmbed ? 232 : 405}px)`,
      fontSize: '1.2rem',
    },
    text: {
      marginTop: theme.spacing(1),
      fontSize: 'inherit',
    },
    header: {
      textAlign: 'center',
      fontWeight: 600,
    },
    list: {
      fontWeight: 400,
      '& > li': {
        marginTop: theme.spacing(1),
      },
      '& > li::marker': {
        fontWeight: 500,
      },
    },
    secondaryList: {
      marginLeft: theme.spacing(2),
      padding: 0,
      listStyleType: 'lower-latin',
      '& > li': {
        marginTop: theme.spacing(1),
      },
    },
    contact: {
      textAlign: 'center',
      '& > p': {
        margin: 0,
      },
    },
    plans: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '1fr 1fr',
    },
    planSection: {
      marginTop: theme.spacing(3),
    },
    linkButton: {
      width: 'fit-content',
      fontSize: '1.4rem',
      marginLeft: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
      },
    },
  }),
  { name: 'WellnessPlanTermsAndConditions' },
)

const OmnichannelWellnessPlanTermsAndConditions = ({ onScroll }) => {
  const isPortalEmbed = useSelector(getMembershipIsPortalEmbed)
  const business = useSelector(getCurrentBusiness)
  const classes = useStyles({ isPortalEmbed })
  const { t } = useTranslation(['Common', 'Membership'])
  const contactInfo = (
    <div className={classes.contact}>
      {business.name && <p>{business.name}</p>}
      {business.address && <p>{business.address}</p>}
      {business.address && business.city && (
        <p>
          {business.city}
          {business.zipcode && `, ${business.zipcode}`}
        </p>
      )}
      {business.address && business.city && business.country && (
        <p>{business.country}</p>
      )}
      {business.phone && <p>{business.phone}</p>}
    </div>
  )

  return (
    <div className={classes.root} onScroll={onScroll}>
      <p className={classes.header}>
        {business?.name ?? ''} and its affiliates Membership Plan Terms and
        Conditions
      </p>
      <p className={classes.header}>
        Effective Date: {DateUtils.formatDate(Date())}
      </p>
      <ol className={classes.list}>
        <li>
          These Membership Plan Terms and Conditions (these “Membership Terms”)
          govern the membership plan (the “Plan”) of{' '}
          {business.name ?? 'The Business'} and its affiliates (the “Practice),
          the services for designated pet patient for whom services are provided
          under the Plan (the “Pet Patient”) and the person who pays for and
          manages participation in the Plan and who has ownership of the
          designated Pet Patient (the “Patient”) for whom services are provided
          under the Plan.
        </li>
        <li>
          The Plan’s Start Date shall be the day the Client pays the first
          monthly installment payment due to the Practice. (the “Start Date”).
          <ol className={classNames(classes.list, classes.secondaryList)}>
            <li>
              The Plan covers all the services listed on the itemized service
              list that is attached hereto as Schedule 1 (the “Service List”).
            </li>
            <li>
              The Plan is not transferrable or assignable and applies only to
              the Pet Patient while the Pet Patient is owned by the Client.
            </li>
            <li>
              The Client may obtain services covered under the Plan for the
              Patient only at the Practice identified herein.
            </li>
            <li>
              The Plan does not include any services not listed on the Service
              List issued to the Client.
            </li>
            <li>
              The Plan does not include any services provided by anyone other
              than the Practice or any fees for services recommended as a result
              of an illness or accidental injury, even if the Practice refers
              the Patient, if not included on the Service List.
            </li>
            <li>
              The Plan is not insurance, is not intended as a substitute for
              insurance, and provides different services/benefits than an
              insurance policy. The Practice is not a pet insurance company or
              an insurance agency.
            </li>
            <li>
              The services will at all times be provided by the Practice in
              accordance with the normal standards of veterinary care and
              applicable law. However, the Practice can only provide services
              pursuant to appointments which the Client makes, and it is the
              Client’s responsibility to ensure that the Client and the Patient
              visit the Pet Practice for exams in accordance with the Plan.
            </li>
          </ol>
        </li>
        <li>
          Monthly installments of Plan fees (the “Plan Fees”) will be billed
          directly to the Client’s credit card. The Client is responsible for
          keeping the Client’s payment information up to date. If the Client’s
          payment information changes, Client must notify the Practice via email
          or phone to ensure the Plan remains in full force and effect.
        </li>
        <li>
          The Plan Fees will be charged and collected by the Practice using the
          payment processing vendor selected by the Practice, which may change
          from time to time. The Plan Fees will be collected monthly in
          accordance with the Plan selected by the Client; specifically,
          commencing with the Start Date and continuing every thirty (30) days
          thereafter until the Plan is terminated for any reason whatsoever.
        </li>
        <li>
          The Plan Fees are inclusive of sales tax and all other relevant taxes.
          If the Client fails to inform the Practice of any changes in payment
          information and the Practice is unable to charge and collect the Plan
          Fees as they are due, goods and services available under the Plan will
          be suspended until the Client reinstates the Plan by paying all Plan
          Fees due and owing at the time of payment; provided, however, if any
          Plan Fee is past due for one hundred twenty (120) days or more, the
          Practice will immediately cancel this Plan and discontinue services
        </li>
        <li>
          These Membership Terms are effective as of the Start Date listed in
          section 2 above for an initial term of twelve (12) months (the “Plan
          Term”) and will automatically renew on the yearly anniversary of the
          Start Date (the “Renewal Date”) in each succeeding year for subsequent
          one-year periods until the Client or the Practice cancels this Plan as
          described below. Client is automatically renewed into the same plan
          unless requests a plan change at least 30 days before the Renewal
          Date.
        </li>
        <li>
          With an active Plan, veterinary visits are unlimited as long as visits
          are for routine care, urgent or sick visits, or are considered
          medically necessary by the Practice. The exam fees covered by the Plan
          do not cover diagnostics, procedures or products that may be
          administered, prescribed or recommended by the Practice. Appointment
          changes or cancellations must be made at least twenty-four (24) hours
          before the scheduled time to avoid any cancellation fees. A missed
          appointment fee may also be charged for failure to show up for a
          scheduled appointment.
        </li>
        <li>
          The Client may cancel the Plan at any time by notifying the Practice
          in writing of the Client’s desire to cancel the Plan at
          {contactInfo}
          If the Client cancels the Plan after the Start Date and has not
          received any services under the Plan, the Practice will refund all
          Plan Fees paid by the Client. If the Client cancels the Plan after the
          Start Date of any annual term or after the Patient has received
          services under the Plan, the Client will be responsible for the
          difference between the retail value of all services received and the
          amount paid by the Client for all such services, which amount shall be
          charged by the Practice to the Client’s payment information on file
          upon cancellation of the Plan.
        </li>
        <li>
          Renewal Date fee adjustments: The Practice reserves the right to
          adjust the Plan’s monthly fee at any Renewal Date or cease at any time
          to provide the Plan. The Client will be given notice of any changes to
          the Plan Fees at least thirty (30) days in advance of the Renewal
          Date.
        </li>
        <li>
          The Membership Terms contain the entire agreement between the Client
          and Practice with respect to the Plan and the subject matter hereof.
          The Client is also subject to the Chewy, Inc. Terms and Conditions
          found on chewy.com and provided with these Membership Terms.
        </li>
        <li>
          By making the initial payment of the Plan Fees, the Client agrees to
          and accepts these Membership Terms that govern the Plan.
        </li>
        <li>
          The Client agrees that, in order for the Practice or its agents to
          service the Client’s account, including, but not limited to, providing
          medical reminders, product recalls, or to collect any amounts owed,
          that the Practice or its agents or affiliates may contact the Client
          by telephone, text messages and/or emails, at any telephone number or
          any email address provided to the Practice by Client. Any notice of
          changes given under the provisions of this Agreement must be in
          writing and shall be delivered via e‐mail or prepaid first-class mail.
        </li>
        <li>
          If the Client is also a Chewy, Inc. employee, the Flea, Tick and
          Heartworm discount cannot be added on top of the employee discount.
        </li>
      </ol>
      <KioskLinkButton
        className={classes.linkButton}
        color="secondary"
        onClick={() => {
          window.open('https://www.chewy.com/app/content/privacy', '_blank')
        }}
      >
        {t('Common:CHEWY_PRIVACY_POLICY')}
      </KioskLinkButton>
      <div className={classes.planSection}>
        <p className={classes.header}>Schedule 1</p>
        <div className={classes.plans}>
          <div>
            <p className={classes.header}>Standard Plan</p>
            <ul>
              <li>Enrollment or administrative fees: None</li>
              <li>Renewal: Auto-renews on anniversary</li>
              <li>
                Cancellation: Cancel anytime. Refunds or additional charges are
                calculated and issued based on plan benefits used.
              </li>
              <li>
                Plan benefits
                <ul>
                  <li>
                    Unlimited wellness, sick, and urgent care exams with no exam
                    fee
                  </li>
                  <li>Priority appointments in 24 hours or less</li>
                  <li>All core and lifestyle vaccines</li>
                  <li>2 fecal ova and parasite tests per year</li>
                  <li>1 rapid heartworm test per year (dogs)</li>
                  <li>1 FeLV/FIV test per year (cats)</li>
                  <li>Annual wellness screen at doctor’s discretion</li>
                  <li>
                    10% discount on prescription and non-prescription items in
                    the flea, tick, and heartworm category at Chewy Vet Care and
                    Chewy.com. Discount is capped at $25 per transaction, not
                    including other discounts or promotions.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <p className={classes.header}>Lite Plan</p>
            <ul>
              <li>Enrollment or administrative fees: None</li>
              <li>Renewal: Auto-renews on anniversary</li>
              <li>
                Cancellation: Cancel anytime. Refunds or additional charges are
                calculated and issued based on plan benefits used.
              </li>
              <li>
                Plan benefits
                <ul>
                  <li>
                    Unlimited wellness, sick, and urgent care exams with no exam
                    fee
                  </li>
                  <li>Priority appointments in 24 hours or less</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OmnichannelWellnessPlanTermsAndConditions
