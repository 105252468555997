import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { BillingAddressForm } from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../../store/duck/businesses'
import { getCurrentClient } from '../../../store/duck/clients'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      color: theme.colors.primaryText,
      fontWeight: 500,
      fontSize: '1.6rem',
    },
  }),
  { name: 'BillingAddressScreen' },
)

const BillingAddressScreen = forwardRef(function BillingAddressScreen(
  {
    classes: classesProp,
    className,
    billingAddresses,
    currentBillingAddress,
    formClasses,
  },
  ref,
) {
  const classes = useStyles({ classes: classesProp })
  const { t } = useTranslation('Common')

  const client = useSelector(getCurrentClient)
  const business = useSelector(getCurrentBusiness)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <Grid
      container
      item
      className={className}
      direction="column"
      spacing={isMobile ? 1 : 2}
    >
      <Grid item>
        <Typography className={classes.title}>
          {t('Common:BILLING_ADDRESS')}
        </Typography>
      </Grid>
      <BillingAddressForm
        billingAddresses={billingAddresses}
        business={business}
        classes={formClasses}
        client={client}
        currentBillingAddress={currentBillingAddress}
        ref={ref}
      />
    </Grid>
  )
})

export default BillingAddressScreen
