import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import { Communications, PuiTheme } from '@pbt/pbt-ui-components'
import { MessageTransport } from '@pbt/pbt-ui-components/src/constants/communications'

import { getCurrentBusiness } from '../../../store/duck/businesses'
import { useShouldDisplaySmsConsent } from '../../../store/hooks/clients'
import KioskSelectableButton from '../../buttons/KioskSelectableButton'

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      marginTop: theme.spacing(4),
    },
    title: {
      fontSize: '1.8rem',
    },
    button: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(3),
      maxWidth: 176,
    },
    disabledButton: {
      maxWidth: 'fit-content',
      whiteSpace: 'pre-line',
      lineHeight: 'initial',
    },
  }),
  { name: 'TransportSelect' },
)

const DEFAULT_AVAILABLE_TRANSPORTS = [
  Communications.MessageTransport.EMAIL,
  Communications.MessageTransport.SMS,
]

const selectDefaultTransportFromList = (
  transports: MessageTransport[],
): MessageTransport =>
  R.includes(Communications.MessageTransport.EMAIL, transports)
    ? Communications.MessageTransport.EMAIL
    : (R.head(transports) as MessageTransport)

type TransportConfig = {
  disabled: boolean
  selectText: string
  transport: MessageTransport
}

export interface TransportSelectProps {
  readonly availableTransports: MessageTransport[]
  readonly communicationSmsOptIn: boolean
  readonly setTransport: (transport: MessageTransport) => void
  readonly title: string
  readonly transport: any
}

const TransportSelect = ({
  availableTransports = DEFAULT_AVAILABLE_TRANSPORTS,
  communicationSmsOptIn,
  setTransport,
  title,
  transport,
}: TransportSelectProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Clients', 'Common'])

  const business = useSelector(getCurrentBusiness)
  const shouldDisplaySmsConsent = useShouldDisplaySmsConsent()

  // null and false should be disabled for omnichannel businesses
  const isSmsOptionDisabled =
    shouldDisplaySmsConsent && Boolean(communicationSmsOptIn) === false

  const ConfigByTransport: Record<string, TransportConfig> = {
    [Communications.MessageTransport.EMAIL]: {
      transport: Communications.MessageTransport.EMAIL,
      selectText: 'Email',
      disabled: false,
    },
    [Communications.MessageTransport.SMS]: {
      transport: Communications.MessageTransport.SMS,
      selectText: isSmsOptionDisabled
        ? t('Clients:SMS_CONSENT_DISABLED')
        : t('Common:TEXT_MESSAGE'),
      disabled: isSmsOptionDisabled,
    },
  }

  const transportsToDisplay = business?.smsCommunicationsEnabled
    ? availableTransports
    : R.reject(
        R.equals(Communications.MessageTransport.SMS),
        availableTransports,
      )

  const transportConfigsToDisplay = transportsToDisplay
    .map((it) => ConfigByTransport[it])
    .filter(Boolean)

  useEffect(() => {
    if (!R.includes(transport, transportsToDisplay)) {
      setTransport(selectDefaultTransportFromList(transportsToDisplay))
    }
  }, [transportsToDisplay, transport])

  return (
    <Grid container className={classes.root} direction="column">
      <Grid item>
        <Typography className={classes.title} variant="h5">
          {title}
        </Typography>
      </Grid>
      <Grid container item>
        {transportConfigsToDisplay.map(
          ({ disabled, selectText, transport: transportConfigTransport }) => (
            <KioskSelectableButton
              classes={{
                button: classNames(classes.button, {
                  [classes.disabledButton]: disabled,
                }),
              }}
              disabled={disabled}
              isSelected={transport === transportConfigTransport}
              key={transportConfigTransport}
              onClick={() => setTransport(transportConfigTransport)}
            >
              {selectText}
            </KioskSelectableButton>
          ),
        )}
      </Grid>
    </Grid>
  )
}

export default TransportSelect
