import React from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { parse } from 'query-string'
import { prop, sortBy } from 'ramda'
import {
  AddButton,
  ImageScalingUtils,
  PatientAvatar,
  PuiTheme,
  Utils,
} from '@pbt/pbt-ui-components'
import { Edit } from '@pbt/pbt-ui-components/src/icons'

import Features from '../../../../constants/features'
import { WellnessPlanLevels } from '../../../../constants/wellnessPlansConstants'
import { getCurrentBusinessIsOmniChannel } from '../../../../store/duck/businesses'
import { getCurrentClient } from '../../../../store/duck/clients'
import { getFeatureToggle, getSpecies } from '../../../../store/duck/constants'
import {
  getMembershipSelection,
  getWellnessPlansVersion,
  SelectionType,
} from '../../../../store/duck/wellnessPlans'
// @ts-ignore
import { useNavigateWithQueryString } from '../../../../utils'
import KioskLinkButton from '../../../buttons/KioskLinkButton'
import MembershipTotalsLabel from '../MembershipTotalsLabel'
import {
  getEnabledPlans,
  getPlanByLevel,
  useConvertSelectionToPlans,
  useGroupedWellnessPlans,
  // @ts-ignore
} from '../wellnessPlanUtils'

const AVATAR_SIZE = 40

const useStyles = makeStyles(
  (theme: PuiTheme) => ({
    root: {
      maxHeight: ({
        isPortalEmbed,
        showBaseLevelPlanPrice,
      }: {
        isPortalEmbed: boolean
        showBaseLevelPlanPrice: boolean
      }) =>
        `calc(var(--app-height) - ${showBaseLevelPlanPrice ? 21 : 0}px - ${
          isPortalEmbed ? 232 : 405
        }px)`,
      [theme.breakpoints.down('sm')]: {
        borderTop: theme.constants.tabBorder,
        maxHeight: () => 'calc(var(--app-height) - 410px)',
      },
    },
    table: {},
    tableHead: {
      borderBottom: theme.constants.tabBorder,
    },
    headerCell: {
      padding: theme.spacing(0, 2),
      backgroundColor: theme.colors.tableBackground,
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    patientAvatar: {
      marginRight: theme.spacing(1),
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
    },
    defaultIcon: {
      fontSize: '3rem',
    },
    patientName: {
      color: theme.colors.primaryText,
      fontWeight: 500,
      fontSize: '1.8rem',
    },
    patientInfoCell: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        borderRight: theme.constants.tabBorder,
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
      },
    },
    patientInfoHeaderCell: {
      paddingLeft: theme.spacing(3),
    },
    patientInfoContainer: {
      marginRight: theme.spacing(3),
    },
    row: {
      flexShrink: 0,
      '&:not(:last-of-type)': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    button: {
      fontSize: '1.4rem',
    },
    tableBody: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
      },
    },
    column: {
      padding: theme.spacing(3, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    planNameText: {
      marginTop: theme.spacing(1),
    },
    iconButton: {
      padding: theme.spacing(0.5),
      color: theme.colors.editIconColor,
    },
    planEnrolledText: {
      opacity: 0.5,
    },
    enrolledText: {
      opacity: 0.5,
      fontSize: '1.2rem',
    },
    plusButton: {
      width: 40,
      height: 40,
    },
    plusIcon: {
      width: 35,
      height: 35,
    },
    addText: {
      fontSize: '1.8rem',
      fontWeight: 400,
    },
    addItem: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 2),
      },
    },
    totalsText: {
      textAlign: 'right',
    },
    patientTotalsText: {
      color: theme.colors.secondaryText,
      fontWeight: 400,
    },
  }),
  { name: 'MembershipSummaryTable' },
)

type Totals = Record<string, number>

interface MembershipSummaryTableProps {
  readonly totals: Totals
  readonly totalsPerPatient: Record<string, Totals>
}

const MembershipSummaryTable = ({
  totals,
  totalsPerPatient,
}: MembershipSummaryTableProps) => {
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation(['Common', 'Membership'])

  const Species = useSelector(getSpecies)
  const client = useSelector(getCurrentClient)
  const selection = useSelector(getMembershipSelection)
  const wellnessPlansVersion = useSelector(getWellnessPlansVersion) || {}

  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isSuppressAddClientsAndPatientsEnabled = useSelector(
    getFeatureToggle(Features.SUPPRESS_ADD_CLIENTS_AND_PATIENTS),
  )

  const showAddPet = !(
    isCurrentBusinessOmniChannel && isSuppressAddClientsAndPatientsEnabled
  )

  const isMobile = useMediaQuery((theme: PuiTheme) =>
    theme.breakpoints.down('sm'),
  )

  const { search } = useLocation()
  const { portalEmbed } = parse(search)
  const isPortalEmbed = Boolean(portalEmbed)

  const allPlans = wellnessPlansVersion?.plans || []
  const baseLevelPlan = getPlanByLevel(WellnessPlanLevels.BASE, allPlans)
  const showBaseLevelPlanPrice = baseLevelPlan?.price > 0
  const plans = getEnabledPlans(wellnessPlansVersion)

  const groupWellnessPlans = useGroupedWellnessPlans()
  const convertSelectionToPlans = useConvertSelectionToPlans()
  const { packages = [], extras = [], tiers = [] } = groupWellnessPlans(plans)

  const classes = useStyles({
    showBaseLevelPlanPrice,
    isPortalEmbed,
  })

  const memberships = sortBy(prop('member'), client?.memberships || [])

  const goToFullDetails = () => {
    navigateWithQueryString({ url: '/membership/plan-details' })
  }

  const editMembership = (patientId: string) => {
    navigateWithQueryString({
      url: '/membership/sign-up',
      options: {
        replace: true,
        state: {
          patientId,
        },
      },
    })
  }

  const onAddPatientRequested = () => {
    navigateWithQueryString({
      url: '/membership/patient-details',
      options: {
        replace: true,
        state: {
          redirectUrl: '/membership/plan-summary',
        },
      },
    })
  }

  const hasTiers = tiers.length > 0
  const hasExtras = extras.length > 0
  const hasPackages = packages.length > 0

  return (
    <TableContainer className={classes.root}>
      <Table stickyHeader className={classes.table}>
        {!isMobile && (
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell
                className={classNames(
                  classes.headerCell,
                  classes.patientInfoHeaderCell,
                )}
              >
                <KioskLinkButton
                  className={classes.button}
                  color="secondary"
                  onClick={goToFullDetails}
                >
                  {t('Common:VIEW_FULL_DETAILS')}
                </KioskLinkButton>
              </TableCell>
              {hasTiers && (
                <TableCell className={classes.headerCell}>
                  {t('Common:PLAN')}
                </TableCell>
              )}
              {hasExtras && (
                <TableCell className={classes.headerCell}>
                  {t('Common:EXTRA')}
                </TableCell>
              )}
              {hasPackages && (
                <TableCell className={classes.headerCell}>
                  {t('Common:SUBSCRIBE_AND_SAVE')}
                </TableCell>
              )}
              <TableCell className={classes.headerCell}>
                <MembershipTotalsLabel
                  alignItems="flex-end"
                  classes={{
                    totalText: classes.totalsText,
                  }}
                  totals={totals}
                />
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody className={classes.tableBody}>
          {memberships.map((membership) => {
            const selectionsForPatient = selection.filter(
              (item: { patientId: string }) =>
                item.patientId === membership.patient.id,
            )
            const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
              membership.patient.photo,
              membership.patient.photoThumbnail,
              AVATAR_SIZE,
            )

            const {
              tiers: tiersSelection = [],
              extras: extrasSelection = [],
              packages: packagesSelection = [],
            } = convertSelectionToPlans(selectionsForPatient)

            const {
              tiers: existingTiers = [],
              extras: existingExtras = [],
              packages: existingPackages = [],
            } = groupWellnessPlans(membership.plans || [])

            const totalsForPatient = Object.keys(
              totalsPerPatient[membership.patient.id],
            )

            const patientCell = (
              <Grid
                container
                item
                alignItems="center"
                justifyContent="space-between"
                wrap="nowrap"
              >
                <Grid
                  container
                  item
                  alignItems="center"
                  className={classes.patientInfoContainer}
                  wrap="nowrap"
                >
                  <PatientAvatar
                    animal={Utils.getConstantName(
                      membership.patient.speciesId,
                      Species,
                    )}
                    classes={{
                      root: classes.patientAvatar,
                      defaultIcon: classes.defaultIcon,
                    }}
                    src={avatarSrc}
                  />
                  <Dotdotdot clamp={1}>
                    <Typography className={classes.patientName} variant="body2">
                      {membership.patient.name}
                    </Typography>
                  </Dotdotdot>
                </Grid>
                <IconButton
                  className={classes.iconButton}
                  size="large"
                  onClick={() => editMembership(membership.patient.id)}
                >
                  <Edit />
                </IconButton>
              </Grid>
            )

            const tierCell =
              tiersSelection.length > 0 || existingTiers.length > 0 ? (
                <>
                  {tiersSelection.map((selectionItem: SelectionType) => {
                    const tier = Utils.findById(selectionItem.planId, tiers)
                    return (
                      <Typography key={selectionItem.planId} variant="body2">
                        {tier.name}
                      </Typography>
                    )
                  })}
                  {existingTiers.map((tier: SelectionType) => (
                    <React.Fragment key={tier.planId}>
                      <Typography
                        className={classes.planEnrolledText}
                        variant="body2"
                      >
                        {tier.planName}
                      </Typography>
                      <Typography
                        className={classes.enrolledText}
                        variant="body2"
                      >
                        ({t('Membership:ENROLLED')})
                      </Typography>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <Typography variant="body2">—</Typography>
              )

            const extrasCell =
              extrasSelection.length > 0 || existingExtras.length > 0 ? (
                <>
                  {extrasSelection.map((selectionItem: SelectionType) => {
                    const extra = Utils.findById(selectionItem.planId, extras)
                    return (
                      <Typography key={selectionItem.planId} variant="body2">
                        {extra.name}
                      </Typography>
                    )
                  })}
                  {existingExtras.map((extra: SelectionType) => (
                    <React.Fragment key={extra.planId}>
                      <Typography
                        className={classes.planEnrolledText}
                        variant="body2"
                      >
                        {extra.planName}
                      </Typography>
                      <Typography
                        className={classes.enrolledText}
                        variant="body2"
                      >
                        ({t('Membership:ENROLLED')})
                      </Typography>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <Typography variant="body2">—</Typography>
              )

            const packagesCell =
              packagesSelection.length > 0 || existingPackages.length > 0 ? (
                <>
                  {packagesSelection.map((selectionItem: SelectionType) => {
                    const packageItem = Utils.findById(
                      selectionItem.planId,
                      packages,
                    )
                    return (
                      <Typography key={selectionItem.planId} variant="body2">
                        {packageItem.name}
                      </Typography>
                    )
                  })}
                  {existingPackages.map((packageItem: SelectionType) => (
                    <React.Fragment key={packageItem.planId}>
                      <Typography
                        className={classes.planEnrolledText}
                        variant="body2"
                      >
                        {packageItem.planName}
                      </Typography>
                      <Typography
                        className={classes.enrolledText}
                        variant="body2"
                      >
                        ({t('Membership:ENROLLED')})
                      </Typography>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <Typography variant="body2">—</Typography>
              )

            const totalsCell =
              totalsForPatient.length > 0 ? (
                <MembershipTotalsLabel
                  alignItems={isMobile ? 'flex-start' : 'flex-end'}
                  classes={{
                    totalText: classes.patientTotalsText,
                  }}
                  showLabel={false}
                  totals={totalsPerPatient[membership.patient.id]}
                />
              ) : (
                <Typography align={isMobile ? 'left' : 'right'} variant="body2">
                  —
                </Typography>
              )

            return (
              <React.Fragment key={membership.patient.id}>
                {isMobile ? (
                  <>
                    <TableRow>
                      <TableCell
                        className={classes.patientInfoCell}
                        colSpan={2}
                      >
                        {patientCell}
                      </TableCell>
                    </TableRow>
                    {hasTiers && (
                      <TableRow>
                        <TableCell className={classes.headerCell}>
                          {t('Common:PLAN')}
                        </TableCell>
                        <TableCell className={classes.column}>
                          {tierCell}
                        </TableCell>
                      </TableRow>
                    )}
                    {hasExtras && (
                      <TableRow>
                        <TableCell className={classes.headerCell}>
                          {t('Common:EXTRA')}
                        </TableCell>
                        <TableCell className={classes.column}>
                          {extrasCell}
                        </TableCell>
                      </TableRow>
                    )}
                    {hasPackages && (
                      <TableRow>
                        <TableCell className={classes.headerCell}>
                          {t('Common:SUBSCRIBE_AND_SAVE')}
                        </TableCell>
                        <TableCell className={classes.column}>
                          {packagesCell}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell className={classes.headerCell}>
                        {t('Common:TOTAL')}
                      </TableCell>
                      <TableCell className={classes.column}>
                        {totalsCell}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <TableRow className={classes.row}>
                    <TableCell className={classes.patientInfoCell}>
                      {patientCell}
                    </TableCell>
                    {hasTiers && (
                      <TableCell className={classes.column}>
                        {tierCell}
                      </TableCell>
                    )}
                    {hasExtras && (
                      <TableCell className={classes.column}>
                        {extrasCell}
                      </TableCell>
                    )}
                    {hasPackages && (
                      <TableCell className={classes.column}>
                        {packagesCell}
                      </TableCell>
                    )}
                    <TableCell className={classes.column}>
                      {totalsCell}
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            )
          })}
          {showAddPet && (
            <TableRow>
              <TableCell colSpan={999}>
                <AddButton
                  addText={t('Common:ADD_ANOTHER_PET')}
                  classes={{
                    plusButton: classes.plusButton,
                    addText: classes.addText,
                    plusIcon: classes.plusIcon,
                    addItem: classes.addItem,
                  }}
                  onAdd={onAddPatientRequested}
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MembershipSummaryTable
